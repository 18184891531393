import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useAllAppAlertHooks } from "../../utils/settings/AppAlertHooks";
import AlertBox from "../../components/AlertBox";
import ModalCreateAppAlert from "../../components/setting/ModalCreateAppAlert";
import moment from "moment";
import ModalDeleteAppAlert from "../../components/setting/ModalDeleteAppAlert";
import ModalUpdateAppAlert from "../../components/setting/ModalUpdateAppAlert";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function AppAlertPage() {
  let { hasAccess } = useControlPermission();

  const { data, getInfoAlertApp } = useAllAppAlertHooks();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [modal, setModal] = useState(null);
  const [selected, setSelected] = useState({});

  const selectModal = (modalName, selectedData) => {
    setModal(modalName);
    setSelected(selectedData);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    getInfoAlertApp();
  };

  return (
    <MainLayout resource_key="app-user">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Pengumuman Pelanggan</h1>
            </div>
            <div className="col-auto">
              {hasAccess("create-info") && (
                <button className="btn btn-primary" onClick={() => setModal("create-app-alert")}>
                  <i className="fa fa-plus"></i> Buat Pengumuman
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Box title="Pengumuman">
              <AlertBox {...alert} setAlert={setAlert} />
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Judul</th>
                    <th>Deskripsi Pendek</th>
                    <th>Deskripsi</th>
                    <th>Status</th>
                    <th>Dibuat pada</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.length === 0 && (
                    <tr>
                      <td colSpan={6} className="text-center">
                        Tidak ada pengumuman
                      </td>
                    </tr>
                  )}
                  {data.map((info) => (
                    <tr key={info.id}>
                      <td>{info.title}</td>
                      <td>{info.short_desc || "-"}</td>
                      <td>{info.description}</td>
                      <td>
                        {info.status === 1 && <span className="badge badge-success">Aktif</span>}
                        {info.status === 0 && (
                          <span className="badge badge-danger">Tidak Aktif</span>
                        )}
                      </td>
                      <td className="text-nowrap">
                        {moment.unix(info.createdAt).format("DD MMM YYYY - HH:mm")}
                      </td>
                      <td>
                        {hasAccess("update-info") && (
                          <button
                            className="btn btn-sm btn-block text-nowrap btn-info"
                            onClick={() => selectModal("update-app-alert", info)}
                          >
                            <i className="fa fa-pencil"></i> Edit
                          </button>
                        )}
                        {hasAccess("delete-info") && (
                          <button
                            className="btn btn-sm btn-block text-nowrap btn-danger"
                            onClick={() => selectModal("delete-app-alert", info)}
                          >
                            <i className="fa fa-trash"></i> Hapus
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </div>
        </div>
      </div>

      <ModalCreateAppAlert
        show={modal === "create-app-alert"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />

      <ModalUpdateAppAlert
        show={modal === "update-app-alert"}
        onClose={() => setModal(null)}
        selected={selected}
        onSuccess={onSuccess}
      />

      <ModalDeleteAppAlert
        show={modal === "delete-app-alert"}
        onClose={() => setModal(null)}
        selected={selected}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
