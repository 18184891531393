export const menu = [
  {
    "path": "/users",
    "name": "Pendaftaran",
    "icon": "fas fa-users",
    "permission_key": 'view-registration',
    "roles": ['ADMIN', 'FINANCE', 'CS']
  },
  {
    "path": "/jadwal",
    "name": "Penjadwalan",
    "icon": "fas fa-calendar-alt",
    "permission_key": 'view-schedule',
    "roles": ['ADMIN', 'TECHNICIAN', 'LEAD_TECHNICIAN', 'CS'],
    "children": [
      {
        "path": "/jadwal/instalasi",
        "name": "Jadwal Instalasi",
        "permission_key": 'view-schedule-installation',
      },
      {
        "path": "/jadwal/dismantle",
        "name": "Jadwal Dismantle",
        "permission_key": 'view-schedule-dismantle',
      },
      {
        "path": "/jadwal/relocate",
        "name": "Jadwal Relokasi",
        "permission_key": 'view-schedule-relocate',
      },
    ]
  },
  {
    "path": "/pelanggan",
    "name": "Manajemen Pelanggan",
    "icon": "fas fa-user-friends",
    "permission_key": 'view-customer',
    "roles": ['ADMIN', 'FINANCE', 'CS'],
    "children": [
      {
        "path": "/pelanggan",
        "name": "Manajemen Pelanggan",
        "permission_key": 'view-customer',
        "roles": ['ADMIN', 'FINANCE', 'CS'],
      },
      {
        "path": "/pelanggan/request",
        "name": "Pengajuan Ganti Paket",
        "permission_key": 'view-change-package',
        "roles": ['ADMIN', 'FINANCE', 'CS']
      },
      {
        "path": "/pelanggan/jatuh-tempo",
        "name": "Pelanggan Belum Bayar",
        "permission_key": 'view-unpaid-customer',
        "roles": ['ADMIN', 'FINANCE', 'CS']
      },
      {
        "path": "/pelanggan/report-customer",
        "name": "Report Pelanggan",
        "permission_key": 'view-customer-status-report',
      },
      {
        "path": "/pelanggan/report-customer-due",
        "name": "Report Jatuh Tempo",
        "permission_key": 'view-customer-due-report',
      }
    ]
  },
  {
    "path": "/sales/billing",
    "name": "Pembayaran",
    "permission_key": 'view-partner-payment',
    "icon": "fas fa-file-invoice-dollar",
    "roles": ['PARTNER'],
  },
  {
    "path": "/teknisi/riwayat",
    "name": "Riwayat Pemasangan",
    "permission_key": 'view-partner-installation',
    "icon": "fas fa-history",
    "roles": ['PARTNER'],
  },
  {
    "path": "/tickets",
    "name": "Tiket Bantuan",
    "icon": "fas fa-headset",
    "permission_key": 'view-ticket',
    "roles": ['ADMIN', 'FINANCE', 'CS', 'TECHNICIAN', 'NOC', 'LEAD_TECHNICIAN']
  },
  {
    "path": "/sales",
    "name": "Sales",
    "icon": "fas fa-people-arrows",
    "roles": ['ADMIN', 'CS', 'SALES', 'AFFILIATOR', 'LEAD_SALES'],
    "permission_key": 'view-sales',
    "children": [
      {
        "path": "/sales/register",
        "name": "Form Pendaftaran",
        "permission_key": 'view-sales-registration-form',
      },
      {
        "path": "/sales/data",
        "name": "Data Pendaftaran",
        "permission_key": 'view-sales-registration',
      },
      {
        "path": "/sales/billing",
        "name": "Pembayaran",
        "permission_key": 'view-sales-billing',
        "roles": ['ADMIN', 'LEAD_SALES'],
      }
    ]
  },
  {
    "path": "/point-sales",
    "name": "Poin Sales & Affiliator",
    "permission_key": 'view-sales-point',
    "icon": "fas fa-poll",
    "roles": ['ADMIN', 'FINANCE', 'LEAD_SALES']
  },
  {
    "path": "/pelanggan-partner",
    "name": "Report Pelanggan",
    "permission_key": 'view-partner-infrastructure-customer',
    "icon": "fas fa-people-group",
  },
  {
    "path": "/billing-partner",
    "name": "Report Pembayaran",
    "permission_key": 'view-partner-infrastructure-billing',
    "icon": "fas fa-cash-register",
  },
  {
    "path": "/finance",
    "name": "Keuangan",
    "icon": "fa-solid fa-money-bill-wave-alt",
    "permission_key": 'view-finance',
    "roles": ['ADMIN', 'FINANCE', 'CS'],
    "children": [
      {
        "path": "/finance/billing",
        "name": "Billing",
        "permission_key": 'view-finance-billing',
        "roles": ['ADMIN', 'FINANCE', 'CS'],
      },
      {
        "path": "/finance/auto-transfer-list",
        "name": "Auto Transfer List",
        "permission_key": 'view-finance-transferhistory',
        "roles": ['ADMIN', 'FINANCE'],
      },
      {
        "path": "/finance/report-payment",
        "name": "Report Revenue",
        "permission_key": 'view-customer-payment-report',
        "roles": ['ADMIN', 'FINANCE'],
      },
      {
        "path": "/finance/report-commissions",
        "name": "Report Komisi",
        "permission_key": 'view-commission-disbursement-report',
      },
    ]
  },
  {
    "path": "/setup-modem",
    "name": "Modem",
    "icon": "fas fa-wifi",
    "permission_key": 'view-modem',
    "roles": ['ADMIN', 'TECHNICIAN', 'NOC', 'LEAD_TECHNICIAN'],
    "children": [
      {
        "path": "/setup-modem/form",
        "name": "Form Setup Modem",
        "permission_key": 'view-modem-setup',
      },
      {
        "path": "/setup-modem/data-modem",
        "name": "Data Modem",
        "permission_key": 'view-modem',
      },
      {
        "path": "/setup-modem/riwayat-modem",
        "name": "Riwayat Instalasi Modem",
        "permission_key": 'view-modem-history',
      },
      {
        "path": "/setup-modem/olt",
        "name": "OLT",
        "permission_key": 'view-olt',
      },
      {
        "path": "/setup-modem/olt-profile",
        "name": "OLT Profile",
        "permission_key": 'view-olt-profile',
      }
    ]
  },
  {
    "path": "/infrastructure",
    "name": "Infrastruktur",
    "icon": "fas fa-network-wired",
    "permission_key": 'performance-report',
    "roles": ['ADMIN', 'TECHNICIAN', 'NOC', 'LEAD_TECHNICIAN'],
    "children": [
      {
        "path": "/infrastructure/report",
        "name": "Project Performance",
        "permission_key": 'performance-report',
      },
      {
        "path": "/infrastructure/olt-slot",
        "name": "Ketersediaan OLT",
        "permission_key": 'view-olt-report',
      }
    ]
  },
  {
    "path": "/form-bast",
    "name": "Report BAST",
    "icon": "fas fa-list-alt",
    "permission_key": 'view-bast',
    "roles": ['ADMIN', 'TECHNICIAN', 'LEAD_TECHNICIAN'],
    "children": [
      {
        "path": "/form-bast/instalasi",
        "name": "Survey & Instalasi",
        "permission_key": 'view-bast-installation',
        "icon": "fas fa-user-shield"
      },
      {
        "path": "/form-bast/dismantle",
        "name": "Dismantle",
        "permission_key": 'view-bast-dismantle',
        "icon": "fas fa-user-shield"
      },
      {
        "path": "/form-bast/relocate",
        "name": "Relokasi",
        "permission_key": 'view-bast-relocate',
        "icon": "fas fa-user-shield"
      }
    ]
  },
  {
    "path": "/paket",
    "name": "Manajemen Paket",
    "icon": "fas fa-box-open",
    "permission_key": 'view-package',
    "roles": ['ADMIN'],
    "children": [
      {
        "path": "/paket",
        "name": "Paket",
        "permission_key": 'view-package',
        "icon": "fas fa-user-shield"
      },
      {
        "path": "/paket/tipe",
        "name": "Tipe dan Varian",
        "permission_key": 'view-package-type-varian',
        "icon": "fas fa-user-shield"
      }
    ]
  },
  {
    "path": "/teknisi",
    "name": "Manajemen Teknisi",
    "icon": "fas fa-user-cog",
    "permission_key": 'view-technician',
    "roles": ['ADMIN', 'LEAD_TECHNICIAN', 'FINANCE'],
    "children": [
      {
        "path": "/teknisi",
        "name": "Manajemen Teknisi",
        "permission_key": 'view-technician',
        "icon": "fas fa-user-shield",
        "roles": ['ADMIN', 'LEAD_TECHNICIAN'],
      },
      {
        "path": "/teknisi/riwayat",
        "name": "Riwayat Pemasangan",
        "icon": "fas fa-user-shield",
        "permission_key": 'view-technician-installation',
        "roles": ['ADMIN', 'LEAD_TECHNICIAN', 'FINANCE'],
      }
    ]
  },
  {
    "path": "/promo",
    "name": "Manajemen Promo",
    "icon": "fas fa-percentage",
    "roles": ['ADMIN'],
    "children": [
      {
        "path": "/promo/package",
        "name": "Promo Paket",
        "icon": "fas fa-percentage"
      }
    ]
  },
  {
    "path": "/project",
    "name": "Manajemen Project",
    "icon": "fas fa-project-diagram",
    "permission_key": 'view-project',
    "roles": ['ADMIN', 'WAREHOUSE'],
    "children": [
      {
        "path": "/project",
        "name": "Project",
        "permission_key": 'view-project',
        "icon": "fas fa-percentage"
      },
      {
        "path": "/project/partners",
        "name": "Mitra",
        "permission_key": 'manage-partner',
        "icon": "fas fa-percentage"
      },
      {
        "path": "/project/vendor",
        "permission_key": 'view-vendor',
        "name": "Vendor Project",
        "icon": "fas fa-percentage"
      },
    ]
  },
  {
    "path": "/warehouses",
    "name": "Manajemen Inventory",
    "icon": "fas fa-warehouse",
    "permission_key": 'view-inventory',
    "roles": ['ADMIN', 'WAREHOUSE'],
    "children": [
      {
        "path": "/warehouses",
        "name": "Gudang",
        "permission_key": 'view-warehouse',
        "icon": "fas fa-user-shield"
      },
      {
        "path": "/warehouses/inventory",
        "name": "Inventori Master",
        "permission_key": 'view-inventory-master',
        "icon": "fas fa-user-shield"
      },
      {
        "path": "/warehouses/categories",
        "name": "Kategori",
        "permission_key": 'view-inventory-category',
        "icon": "fas fa-user-shield"
      },
      {
        "path": "/warehouses/return",
        "permission_key": 'view-inventory-return',
        "name": "Barang Kembali",
      },
    ]
  },
  {
    "path": "/roles",
    "name": "Manajemen Akun & Role",
    "icon": "fas fa-user-shield",
    "permission_key": 'view-account',
    "roles": ['ADMIN']
  },
  {
    "path": "/apps",
    "name": "Aplikasi",
    "icon": "fas fa-mobile-screen",
    "permission_key": 'view-apps-menu',
    "children": [
      {
        "path": "/apps/user-mobile",
        "name": "Pengguna MyViberlink",
        "permission_key": 'view-user-account',
      },
      {
        "path": "/apps/banner",
        "name": "Banner Aplikasi",
        "permission_key": 'view-banner-menu',
      },
      {
        "path": "/apps/app-alert",
        "name": "Pengumuman Pelanggan",
        "permission_key": 'view-info-menu',
      },
    ]
  },
  {
    "path": "/settings",
    "name": "Setting",
    "icon": "fas fa-cog",
    "permission_key": 'view-setting',
    "roles": ['ADMIN'],
    "children": [
      {
        "path": "/settings",
        "name": "General Setting",
        "permission_key": 'view-setting-general',
        "icon": "fas fa-user-shield"
      },
      {
        "path": "/settings/audit-log",
        "name": "Audit Log",
        "permission_key": 'view-setting-audit-log',
        "icon": "fas fa-user-shield"
      },
      {
        "path": "/settings/tnc",
        "name": "Term and Condition",
        "permission_key": 'view-setting-tnc',
        "icon": "fas fa-user-shield"
      },
      {
        "path": "/settings/coverage",
        "name": "Setting Coverage",
        "permission_key": 'view-setting-coverage',
        "icon": "fas fa-user-shield"
      },
      {
        "path": "/settings/rbac",
        "permission_key": 'view-permission',
        "name": "Setting Permission",
        "icon": "fas fa-user-shield"
      }
    ]
  },
  {
    "path": "/profile",
    "name": "Profile",
    "icon": "fas fa-user",
    "permission_key": 'view-profile',
    "roles": ['ADMIN']
  },
]