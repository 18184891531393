import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useAllAppBannerHooks } from "../../utils/settings/AppBannerHooks";
import ModalCreateBanner from "../../components/setting/ModalCreateBanner";
import ModalUpdateBanner from "../../components/setting/ModalUpdateBanner";
import AlertBox from "../../components/AlertBox";
import ModalDeleteBanner from "../../components/setting/ModalDeleteBanner";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function BannerSettingPage() {
  let { hasAccess } = useControlPermission();

  const { data, getAllBanner } = useAllAppBannerHooks();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [modal, setModal] = useState(null);
  const [selected, setSelected] = useState({});

  const selectModal = (modalName, selectedData) => {
    setModal(modalName);
    setSelected(selectedData);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    getAllBanner();
  };

  return (
    <MainLayout resource_key="app-user">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Banner Aplikasi</h1>
            </div>
            <div className="col-auto">
              {hasAccess("create-banner") && (
                <button className="btn btn-primary" onClick={() => setModal("create-banner")}>
                  <i className="fa fa-plus"></i> Tambah Banner Baru
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Box title="Banner Aplikasi">
              <AlertBox {...alert} setAlert={setAlert} />
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Gambar</th>
                    <th>Judul</th>
                    <th>Deskripsi</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.length === 0 && (
                    <tr>
                      <td colSpan={5} className="text-center">
                        Tidak ada pengumuman
                      </td>
                    </tr>
                  )}
                  {data.map((banner) => (
                    <tr key={banner.id}>
                      <td>
                        <img src={banner.img} alt={banner.title} style={{ width: "200px" }} />
                      </td>
                      <td>{banner.title}</td>
                      <td>{banner.description}</td>
                      <td>
                        {banner.status === 1 && <span className="badge badge-success">Aktif</span>}
                        {banner.status === 0 && (
                          <span className="badge badge-danger">Tidak Aktif</span>
                        )}
                      </td>
                      <td>
                        {hasAccess("update-banner") && (
                          <button
                            className="btn btn-sm btn-block text-nowrap btn-info"
                            onClick={() => selectModal("update-app-banner", banner)}
                          >
                            <i className="fa fa-pencil"></i> Edit
                          </button>
                        )}
                        {hasAccess("delete-banner") && (
                          <button
                            className="btn btn-sm btn-block text-nowrap btn-danger"
                            onClick={() => selectModal("delete-app-banner", banner)}
                          >
                            <i className="fa fa-trash"></i> Hapus
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </div>
        </div>
      </div>

      <ModalCreateBanner
        show={modal === "create-banner"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />

      <ModalUpdateBanner
        show={modal === "update-app-banner"}
        onClose={() => setModal(null)}
        selected={selected}
        onSuccess={onSuccess}
      />

      <ModalDeleteBanner
        show={modal === "delete-app-banner"}
        onClose={() => setModal(null)}
        selected={selected}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
